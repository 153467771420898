/****************************** components ******************************/

@import "./components/mixin";
@import "./components/default";
@import "./components/normalize";
@import "./components/reset";
@import "./components/grid";
@import "./components/case";

/****************************** style ******************************/

body,
button,
input,
select,
textarea {
    font-family: "Microsoft YaHei" !important; 
    font: 12px/1.4 "\5FAE\8F6F\96C5\9ED1", "arial";
    color: #5a5a5a;
    font-size: 12px;
}

html {
    min-width: 1200px;
}

ol li,
ul li {
    list-style-type: none;
    vertical-align: middle;
}

img {
    border: none;
    vertical-align: middle;
}

input {
    vertical-align: middle;
    font: 12px/1.4 "\5FAE\8F6F\96C5\9ED1", "arial";
    outline: none;
}

table {
    border-collapse: collapse;
    border-spacing: 0;
}

a {
    text-decoration: none;
}

.clear {
    clear: both;
}

.clearfix {
    *zoom: 1;
}

.clearfix:after {
    content: '';
    display: block;
    height: 0;
    clear: both;
    visibility: hidden;
}

.fl {
    float: left;
}

.fr {
    float: right;
}

.w1200 {
    width: 1200px;
    margin: 0 auto;
    position: relative;
}

.header {
    .header_t {
        width: 100%;
        height: 40px;
        line-height: 40px;
        background-color: rgb(242, 242, 242);
        border-bottom: 1px solid rgb(229, 229, 229);
        p {
            color: rgb(102, 102, 102);
            font-size: 14px;
        }
    }
    .header_b {
        padding: 30px 0 20px;
        .header_bl {
            margin-right: 120px;
            img {
                height: 60px;
            }
        }
        .header_bm {
            ul {
                margin-top: 20px;
                li {
                    float: left;
                    margin-right: 10px;
                    img {
                        float: left;
                    }
                    span {
                        font-size: 15px;
                        color: rgb(128, 128, 128);
                    }
                }
            }
        }
        .header_br {
            img {
                width: 64px;
                height: 64px;
                float: left;
            }
            p {
                color: rgb(255, 51, 51);
                width: 250px;
                font-weight: bold;
            }
            .p1 {
                font-size: 23px;
            }
            .p2 {
                font-size: 26px;
            }
        }
    }
    .nav {
        .nav_l {
            width: 240px;
            a {
                display: block;
                width: 240px;
                height: 50px;
                line-height: 50px;
                background-color: rgb(64, 181, 245);
                text-align: center;
                color: #fff;
                font-size: 18px;
                img {
                    margin-left: 5px;
                }
            }
        }
        .nav_r {
            width: 960px;
            padding: 0 20px;
            ul {
                li {
                    float: left;
                    a {
                        display: block;
                        width: 150px;
                        height: 50px;
                        line-height: 50px;
                        padding: 0 5px;
                        text-align: center;
                        font-size: 14px;
                        &:hover {
                            color: #3db8f5;
                        }
                    }
                    a.active {
                        color: #3db8f5;
                    }
                }
            }
        }
    }
}

.imain {
    .box_banner {
        width: 100%;
        height: 500px;
        margin: 0 auto;
        position: relative;
        z-index: 1;
        .banner {
            height: 500px;
            position: relative;
            z-index: 1;
        }
        .w1200 {
            top: -500px;
            ul {
                li {
                    height: 99px;
                    background-color: rgba(0, 0, 0,.8);
                    width: 240px;
                    margin-bottom: 1px;
                    .a1 {
                        display: block;
                        text-align: center;
                        font-size: 18px;
                        color: #fff;
                        padding-top: 20px;
                        margin-bottom: 10px;
                        img {
                            margin-right: 10px;
                        }
                    }
                    .a2 {
                        font-size: 14px;
                        color: #ccc;
                        display: inline-block;
                        padding-right: 10px;
                        &:hover {
                            color: #fff;
                        }
                    }
                    .a2:last-child {
                        padding-right: 0px;
                    }
                    p {
                        text-align: center;
                    }
                }
            }
        }
    }
    .flexslider {
        width: 100%;
        height: 100%;
        .slides {
            li {
                margin: 0 auto;
                position: relative;
                width: 100%;
                height: 500px;
                overflow: hidden;
                zoom: 1;
            }
            a {
                img {
                    width: 100%;
                    height: 500px;
                    display: block;
                }
            }
        }
    }
    .flex-control-nav {
        width: 100%;
        position: absolute;
        bottom: 10px;
        text-align: center;
        z-index: 1;
        li {
            margin: 0 2px;
            display: inline-block;
            zoom: 1;
            *display: inline;
        }
    }
    .flex-control-paging {
        li {
            a {
                background: url(../img/dot.png) no-repeat 0 -16px;
                display: block;
                height: 16px;
                overflow: hidden;
                text-indent: -99em;
                width: 16px;
                cursor: pointer;
            }
        }
        li.active a,
        li a.flex-active {
            background-position: 0 0;
        }
    }
    .icomp_nd {
        .w1200 {
            ul {
                li {
                    float: left;
                    a {
                        display: block;
                        width: 180px;
                        height: 180px;
                        margin: 0 auto;
                        img {
                            display: block;
                            margin: 0px auto;
                            padding-top: 30px;
                        }
                    }
                    .p1 {
                        color: rgb(253, 168, 35);
                        font-family: 微软雅黑;
                        font-size: 20px;
                        text-align: center;
                    }
                    .p2 {
                        color: rgb(102, 102, 102);
                        font-size: 14px;
                        text-align: center;
                    }
                }
                li:nth-child(1) a {
                    background: url(../img/icons-c1a.png) no-repeat center top;
                }
                li:nth-child(2) a {
                    background: url(../img/icons-c2a.png) no-repeat center top;
                }
                li:nth-child(3) a {
                    background: url(../img/icons-c3a.png) no-repeat center top;
                }
                li:nth-child(4) a {
                    background: url(../img/icons-c4a.png) no-repeat center top;
                }
            }
        }
    }
}




/****************************** media ******************************/

@import "./components/media";
